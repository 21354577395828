<script setup>
import { useAuthStore } from '@stores/auth'

const authStore = useAuthStore()
</script>

<template>
  <div class="tw-h-mobile-screen-my-4 tw-flex tw-items-center tw-justify-center md-tall:tw-h-screen">
    <div class="tw-flex tw-h-full tw-w-full tw-rounded-2xl sm:tw-mx-4 md:tw-max-w-2xl lg:tw-max-w-5xl md-tall:tw-h-[740px]">
      <div class="tw-mt-4 tw-hidden tw-w-1/2 tw-flex-col tw-overflow-y-hidden tw-bg-gray-100 lg:tw-flex lg:tw-gap-y-12 lg:tw-rounded-l-2xl lg:tw-rounded-r-none lg:tw-p-16 md-tall:tw-mt-0">
        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-center">
          <div class="tw-flex tw-justify-center">
            <span class="tw-flex tw-justify-center tw-rounded-full tw-bg-[#FAFAFA] tw-p-6 tw-ring-2 tw-ring-white lg:tw-p-16">
              <img src="@icons/logo.svg" class="tw-h-12 tw-font-medium lg:tw-h-24" alt="amp logo">
            </span>
          </div>
        </div>

        <div>
          <p class="tw-font-bold">
            AMP IT UP
          </p>
          <p class="lg:tw-text-lg">
            We create ready-to-use marketing materials and social media posts for your real estate property, and you don't have to do a thing.
          </p>
        </div>
      </div>

      <div class="tw-h-mobile-screen-my-4 tw-mx-4 tw-mt-4 tw-flex tw-w-full tw-flex-col tw-rounded-2xl tw-bg-white tw-p-6 md:tw-h-auto lg:tw-mx-0 lg:tw-w-1/2 lg:tw-rounded-l-none lg:tw-rounded-r-2xl md-tall:tw-mt-0">
        <div class="tw-flex tw-min-h-full tw-flex-col tw-justify-center tw-overflow-y-auto">
          <div class="tw-flex tw-max-h-full tw-flex-col tw-gap-y-6 sm:tw-px-12">
            <div>
              <div class="tw-flex tw-justify-center">
                <img src="@icons/logo.svg" class="tw-h-16 tw-font-medium" alt="amp logo">
              </div>

              <h3 class="tw-mt-4 tw-text-center tw-font-serif tw-text-2xl/8 tw-font-medium tw-text-primary-100" v-text="authStore.currentPageTitle" />
            </div>

            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
